/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n    fragment AccountNavigationSidebar_UserFragment on User {\n        id\n        hasDemographics\n    }\n": types.AccountNavigationSidebar_UserFragmentFragmentDoc,
    "\n    fragment AdminNavigationSidebar_UserFragment on User {\n        id\n        role {\n            id\n            scopes\n        }\n    }\n": types.AdminNavigationSidebar_UserFragmentFragmentDoc,
    "\n    fragment AllProjectsList_ProjectFragment on Project {\n        ...ProjectCard_ProjectFragment\n    }\n": types.AllProjectsList_ProjectFragmentFragmentDoc,
    "\n    fragment ClickableProfilePicture_UserFragment on User {\n        id\n        profilePicture\n    }\n": types.ClickableProfilePicture_UserFragmentFragmentDoc,
    "\n    fragment EditAccountEmailPreferences_UserFragment on User {\n        id\n        preferences {\n            clubEmailConsent\n            businessEmailConsent\n        }\n    }\n": types.EditAccountEmailPreferences_UserFragmentFragmentDoc,
    "\n    mutation EditAccountEmailPreferences_SetMyClubEmailConsent($consent: Boolean!) {\n        setMyClubEmailConsent(consent: $consent) {\n            ...EditAccountEmailPreferences_UserFragment\n        }\n    }\n": types.EditAccountEmailPreferences_SetMyClubEmailConsentDocument,
    "\n    mutation EditAccountEmailPreferences_SetMyBusinessEmailConsent($consent: Boolean!) {\n        setMyBusinessEmailConsent(consent: $consent) {\n            ...EditAccountEmailPreferences_UserFragment\n        }\n    }\n": types.EditAccountEmailPreferences_SetMyBusinessEmailConsentDocument,
    "\n    fragment EditAccountEmails_UserFragment on User {\n        id\n        emails {\n            email\n            confirmed\n        }\n    }\n": types.EditAccountEmails_UserFragmentFragmentDoc,
    "\n    mutation EditAccountProfile_AddMyEmail($newEmail: String!) {\n        addMyEmail(newEmail: $newEmail) {\n            id\n            emails {\n                email\n                confirmed\n            }\n        }\n    }\n": types.EditAccountProfile_AddMyEmailDocument,
    "\n    mutation EditAccountProfile_RemoveMyEmail($email: String!) {\n        removeMyEmail(email: $email) {\n            id\n            emails {\n                email\n                confirmed\n            }\n        }\n    }\n": types.EditAccountProfile_RemoveMyEmailDocument,
    "\n    fragment EditAccountProfile_UserFragment on User {\n        id\n        givenName\n        fullName\n        profilePicture\n    }\n": types.EditAccountProfile_UserFragmentFragmentDoc,
    "\n    mutation EditAccountProfile_SetMyGivenName($newGivenName: String!) {\n        setMyGivenName(newGivenName: $newGivenName) {\n            ...EditAccountProfile_UserFragment\n        }\n    }\n": types.EditAccountProfile_SetMyGivenNameDocument,
    "\n    query ForceOnboarding_GetMe {\n        me {\n            preferences {\n                onboarded\n            }\n        }\n    }\n": types.ForceOnboarding_GetMeDocument,
    "\n    fragment LinkLegacyMembership_UserFragment on User {\n        ...MembershipCard_UserFragment\n    }\n": types.LinkLegacyMembership_UserFragmentFragmentDoc,
    "\n    mutation LinkLegacyMembership_ValidateLegacyMembership($validationInfo: LegacyMembershipValidationInfo!) {\n        validateLegacyMembership(validationInfo: $validationInfo) {\n            ... on LegacyMembershipValidationResultFail {\n                reason\n            }\n            ... on LegacyMembershipValidationResultMoreInfo {\n                supplyOrderNum\n                supplyEmail\n                supplyBillingName\n                supplyPhone\n                minRequiredValidators\n            }\n            ... on LegacyMembershipValidationResultSuccess {\n                newMembership {\n                    ...MembershipCard_MembershipFragment\n                }\n            }\n        }\n    }\n": types.LinkLegacyMembership_ValidateLegacyMembershipDocument,
    "\n    query LoggedInConditional_GetMe {\n        me {\n            ...LoggedInConditional_UserFragment\n        }\n    }\n": types.LoggedInConditional_GetMeDocument,
    "\n    fragment LoggedInConditional_UserFragment on User {\n        id\n        givenName\n        fullName\n        role {\n            id\n            scopes\n        }\n    }\n": types.LoggedInConditional_UserFragmentFragmentDoc,
    "\n    fragment ManageUser_UserFragment on User {\n        id\n        fullName\n        givenName\n        ucfEmail\n        earliestMembershipTimestamp\n        hasDemographics\n        membership {\n            ...MembershipCard_MembershipFragment\n        }\n        preferences {\n            onboarded\n        }\n\n        ...ProfilePicture_UserFragment\n        ...MembershipCard_UserFragment\n    }\n": types.ManageUser_UserFragmentFragmentDoc,
    "\n    mutation ManageUser_SetNames($userId: ID!, $newGivenName: String!, $newFullName: String!) {\n        setGivenName(userId: $userId, newGivenName: $newGivenName) {\n            id\n        }\n        setFullName(userId: $userId, newFullName: $newFullName) {\n            id\n        }\n    }\n": types.ManageUser_SetNamesDocument,
    "\n    mutation ManageUser_ClearProfilePicture($userId: ID!) {\n        clearProfilePicture(userId: $userId) {\n            id\n        }\n    }\n": types.ManageUser_ClearProfilePictureDocument,
    "\n    mutation ManageUser_ForceReOnboard($userId: ID!) {\n        forceReOnboard(userId: $userId) {\n            id\n        }\n    }\n": types.ManageUser_ForceReOnboardDocument,
    "\n    mutation ManageUser_GrantMembership($userId: ID!, $timestamp: Int!) {\n        grantMembership(userId: $userId, timestamp: $timestamp) {\n            id\n        }\n    }\n": types.ManageUser_GrantMembershipDocument,
    "\n    mutation ManageUser_RevokeMembership($userId: ID!) {\n        revokeMembership(userId: $userId) {\n            id\n        }\n    }\n": types.ManageUser_RevokeMembershipDocument,
    "\n    fragment ManageUserCard_UserFragment on User {\n        id\n        fullName\n        membership {\n            __typename\n        }\n        hasDemographics\n\n        ...ProfilePicture_UserFragment\n    }\n": types.ManageUserCard_UserFragmentFragmentDoc,
    "\n    fragment MembershipCard_UserFragment on User {\n        id\n        fullName\n    }\n": types.MembershipCard_UserFragmentFragmentDoc,
    "\n    fragment MembershipCard_MembershipFragment on Membership {\n        purchaseTimestamp\n        expiresAtTimestamp\n        renewableAtTimestamp\n        flags {\n            name\n            color\n            visible\n        }\n    }\n": types.MembershipCard_MembershipFragmentFragmentDoc,
    "\n    fragment ProfilePicture_UserFragment on User {\n        id\n        profilePicture\n    }\n": types.ProfilePicture_UserFragmentFragmentDoc,
    "\n    fragment ProjectCard_ProjectFragment on Project {\n        id\n        name\n        accessibleRoles {\n            id\n        }\n        sessions {\n            id\n            name\n        }\n    }\n": types.ProjectCard_ProjectFragmentFragmentDoc,
    "\n    query SearchAllUsers($search: String!) {\n        searchAllUsers(search: $search) {\n            ...ManageUserCard_UserFragment\n        }\n    }\n": types.SearchAllUsersDocument,
    "\nmutation UploadProfilePicture_SetMyProfilePictureMutation($profilePicture: Upload!) {\n  setMyProfilePicture(profilePicture: $profilePicture) {\n    id\n    profilePicture\n  }\n}": types.UploadProfilePicture_SetMyProfilePictureMutationDocument,
    "\n    fragment OnboardingEmails_UserFragment on User {\n        id\n        ...EditAccountEmails_UserFragment\n        ...EditAccountEmailPreferences_UserFragment\n    }\n": types.OnboardingEmails_UserFragmentFragmentDoc,
    "\n    mutation OnboardingFinish_CreateMembershipPurchaseSession {\n        finishMyOnboarding {\n            id\n        }\n    }\n": types.OnboardingFinish_CreateMembershipPurchaseSessionDocument,
    "\n    fragment OnboardingMembership_UserFragment on User {\n        id\n        ...MembershipCard_UserFragment\n        ...LinkLegacyMembership_UserFragment\n        membership {\n            ...MembershipCard_MembershipFragment\n        }\n    }\n": types.OnboardingMembership_UserFragmentFragmentDoc,
    "\n    mutation OnboardingMembership_CreateMembershipPurchaseSession {\n        createMembershipPurchaseSession {\n            id\n            paymentLink\n        }\n    }\n": types.OnboardingMembership_CreateMembershipPurchaseSessionDocument,
    "\n    fragment OnboardingProfile_UserFragment on User {\n        id\n        ...EditAccountProfile_UserFragment\n    }\n": types.OnboardingProfile_UserFragmentFragmentDoc,
    "\n    mutation SubmitUserJwtForAttendance($subjectType: String!, $subjectId: ID!, $jwtCode: String!) {\n        submitUserJwtForAttendance(subjectType: $subjectType, subjectId: $subjectId, jwtCode: $jwtCode) {\n            ...AdminProjectAttendanceAdminscannerPage_AdminScanAttendanceResultFragment\n        }\n    }\n": types.SubmitUserJwtForAttendanceDocument,
    "\n    fragment AdminProjectAttendanceAdminscannerPage_AdminScanAttendanceResultFragment on AdminScanAttendanceResult {\n        __typename\n        ... on AdminScanAttendanceResultSuccess {\n            timestamp\n            type\n            subject {\n                id\n                name\n            }\n            user {\n                id\n                fullName\n                membership {\n                    __typename\n                }\n                hasDemographics\n\n                ...ProfilePicture_UserFragment\n            }\n        }\n        ... on AdminScanAttendanceResultFail {\n            reason\n        }\n    }\n": types.AdminProjectAttendanceAdminscannerPage_AdminScanAttendanceResultFragmentFragmentDoc,
    "\n    query GenerateLiveJwtQr($projectSessionId: ID!) {\n        generateLiveJwtQr(subjectType: \"ProjectSession\", subjectId: $projectSessionId)\n    }\n": types.GenerateLiveJwtQrDocument,
    "\n    mutation ConfirmEmailHandler_ConfirmEmail($code: String!) {\n        confirmEmail(code: $code)\n    }\n": types.ConfirmEmailHandler_ConfirmEmailDocument,
    "\n    query ConfirmEmailHandler_GetMe {\n        me {\n            preferences {\n                onboarded\n            }\n        }\n    }\n": types.ConfirmEmailHandler_GetMeDocument,
    "\n    query EcwidSsoHandler_GetMe {\n        me {\n            id\n            fullName\n            membership {\n                purchaseTimestamp\n            }\n        }\n    }\n": types.EcwidSsoHandler_GetMeDocument,
    "\n    mutation PurchaseMembershipHandler_CreateMembershipPurchaseSession {\n        createMembershipPurchaseSession {\n            id\n            paymentLink\n        }\n    }\n": types.PurchaseMembershipHandler_CreateMembershipPurchaseSessionDocument,
    "\n    query RDContractHandler_GetMe {\n        me {\n            id\n        }\n    }\n": types.RdContractHandler_GetMeDocument,
    "\n    mutation SubmitUserJwtForEventDocument($jwtCode: String!, $override: Boolean!) {\n        submitUserJwtForTemp_GBM_3_27_2025(jwtCode: $jwtCode, override: $override) {\n            ...AdminScannerPage_ACPScanResultFragment\n        }\n    }\n": types.SubmitUserJwtForEventDocumentDocument,
    "\n    fragment AdminScannerPage_ACPScanResultFragment on ACPScanResult {\n        __typename\n        ... on ACPScanResultSuccess {\n            timestamp\n            user {\n                id\n                fullName\n                membership {\n                    __typename\n                }\n                hasDemographics\n\n                ...ProfilePicture_UserFragment\n            }\n        }\n        ... on ACPScanResultFail {\n            reason\n            canOverride\n        }\n    }\n": types.AdminScannerPage_AcpScanResultFragmentFragmentDoc,
    "\n    query AccountDemographicsPage_GetMe {\n        me {\n            hasDemographics\n        }\n    }\n": types.AccountDemographicsPage_GetMeDocument,
    "\n    query AccountDemographicsPage_GetMyDemographicsSurveyUrl {\n        myDemographicsSurveyUrl\n    }\n": types.AccountDemographicsPage_GetMyDemographicsSurveyUrlDocument,
    "\n    query AccountEmailsPage_GetMe {\n        me {\n            ...EditAccountEmails_UserFragment\n            ...EditAccountEmailPreferences_UserFragment\n        }\n    }\n": types.AccountEmailsPage_GetMeDocument,
    "\n    query AccountLayout_GetMe {\n        me {\n            ...AccountNavigationSidebar_UserFragment\n        }\n    }\n": types.AccountLayout_GetMeDocument,
    "\n    query AccountLegacyMembershipPageBody_GetMe {\n        me {\n            ...LinkLegacyMembership_UserFragment\n        }\n    }\n": types.AccountLegacyMembershipPageBody_GetMeDocument,
    "\n    query AccountMembershipPage_GetMe {\n        me {\n            ...MembershipCard_UserFragment\n            membership {\n                ...MembershipCard_MembershipFragment\n            }\n        }\n    }\n": types.AccountMembershipPage_GetMeDocument,
    "\n    mutation AccountMembershipPageBody_CreateMembershipPurchaseSession {\n        createMembershipPurchaseSession {\n            id\n            paymentLink\n        }\n    }\n": types.AccountMembershipPageBody_CreateMembershipPurchaseSessionDocument,
    "\n    query AccountProfilePage_GetMe {\n        me {\n            ...EditAccountProfile_UserFragment\n            ...ClickableProfilePicture_UserFragment\n        }\n    }\n": types.AccountProfilePage_GetMeDocument,
    "\n    query AdminLayout_GetMe {\n        me {\n            ...AdminNavigationSidebar_UserFragment\n        }\n    }\n": types.AdminLayout_GetMeDocument,
    "\n    query AdminProjectsPage_GetMeAndProjects {\n        me {\n            role {\n                id\n                scopes\n            }\n        }\n        projects {\n            ...AllProjectsList_ProjectFragment\n        }\n    }\n": types.AdminProjectsPage_GetMeAndProjectsDocument,
    "\n    query AdminUserPage_GetMe {\n        me {\n            role {\n                id\n                scopes\n            }\n        }\n    }\n": types.AdminUserPage_GetMeDocument,
    "\n    query AdminUsersPage_GetUser($userId: ID!) {\n        user(id: $userId) {\n            ...ManageUser_UserFragment\n        }\n    }\n": types.AdminUsersPage_GetUserDocument,
    "\n    query AdminUsersPage_GetMe {\n        me {\n            role {\n                id\n                scopes\n            }\n        }\n    }\n": types.AdminUsersPage_GetMeDocument,
    "\n    query GetProjectAndSession($projectId: ID!, $projectSessionId: ID!) {\n        project(id: $projectId) {\n            id\n            name\n            session(id: $projectSessionId) {\n                id\n                name\n            }\n        }\n    }\n": types.GetProjectAndSessionDocument,
    "\n    query GetProjectSession($projectId: ID!, $projectSessionId: ID!) {\n        project(id: $projectId) {\n            id\n            name\n            session(id: $projectSessionId) {\n                id\n                name\n                weeks {\n                    title\n                    startTimestamp\n                    endTimestamp\n                }\n            }\n        }\n    }\n": types.GetProjectSessionDocument,
    "\n    query GetProjectSessionAttendanceRecords($projectId: ID!, $projectSessionId: ID!, $filter: AttendanceRecordsFilterInput) {\n        project(id: $projectId) {\n            session(id: $projectSessionId) {\n                attendanceRecords(filter: $filter) {\n                    id\n                    timestamp\n                    type\n                    user {\n                        ...ManageUserCard_UserFragment\n                        id\n                        fullName\n                        givenName\n                        membership {\n                            __typename\n                        }\n                        hasDemographics\n                    }\n                }\n            }\n        }\n    }\n": types.GetProjectSessionAttendanceRecordsDocument,
    "\n    query GenerateStaticJwtQrDocument($projectSessionId: ID!) {\n        generateStaticJwtQr(subjectType: \"ProjectSession\", subjectId: $projectSessionId)\n    }\n": types.GenerateStaticJwtQrDocumentDocument,
    "\n    mutation SubmitAttendanceJwt($jwtCode: String!) {\n        submitAttendanceJwt(jwtCode: $jwtCode) {\n            __typename\n            ... on SelfServiceAttendanceResultSuccess {\n                timestamp\n                type\n                subject {\n                    __typename\n                    id\n                    name\n                    ... on ProjectSession {\n                        project {\n                            id\n                            name\n                        }\n                    }\n                }\n            }\n            ... on SelfServiceAttendanceResultFail {\n                reason\n            }\n        }\n    }\n": types.SubmitAttendanceJwtDocument,
    "\n    query MyQrPageBody_GetMyJwtQr {\n        myJwtQr\n        me {\n            ...ProfilePicture_UserFragment\n            fullName\n        }\n    }\n": types.MyQrPageBody_GetMyJwtQrDocument,
    "\n    query OnboardingPageBody_GetMe {\n        me {\n            id\n            preferences {\n                onboarded\n            }\n            ...OnboardingProfile_UserFragment\n            ...OnboardingEmails_UserFragment\n            ...OnboardingMembership_UserFragment\n        }\n    }\n": types.OnboardingPageBody_GetMeDocument,
    "\n    query PaymentCallbackPage_GetMe {\n        me {\n            preferences {\n                onboarded\n            }\n        }\n    }\n": types.PaymentCallbackPage_GetMeDocument,
    "\nquery GetIsMembershipPurchaseSessionCompleted_PaymentCallbackPage($id: ID!) {\n  isMembershipPurchaseSessionCompleted(id: $id)\n}\n": types.GetIsMembershipPurchaseSessionCompleted_PaymentCallbackPageDocument,
    "\n    query Temp_bowlingStatus {\n        temp_bowlingStatus {\n            totalAdmitted\n            reservationsUsed\n        }\n    }\n": types.Temp_BowlingStatusDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment AccountNavigationSidebar_UserFragment on User {\n        id\n        hasDemographics\n    }\n"): (typeof documents)["\n    fragment AccountNavigationSidebar_UserFragment on User {\n        id\n        hasDemographics\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment AdminNavigationSidebar_UserFragment on User {\n        id\n        role {\n            id\n            scopes\n        }\n    }\n"): (typeof documents)["\n    fragment AdminNavigationSidebar_UserFragment on User {\n        id\n        role {\n            id\n            scopes\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment AllProjectsList_ProjectFragment on Project {\n        ...ProjectCard_ProjectFragment\n    }\n"): (typeof documents)["\n    fragment AllProjectsList_ProjectFragment on Project {\n        ...ProjectCard_ProjectFragment\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment ClickableProfilePicture_UserFragment on User {\n        id\n        profilePicture\n    }\n"): (typeof documents)["\n    fragment ClickableProfilePicture_UserFragment on User {\n        id\n        profilePicture\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment EditAccountEmailPreferences_UserFragment on User {\n        id\n        preferences {\n            clubEmailConsent\n            businessEmailConsent\n        }\n    }\n"): (typeof documents)["\n    fragment EditAccountEmailPreferences_UserFragment on User {\n        id\n        preferences {\n            clubEmailConsent\n            businessEmailConsent\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation EditAccountEmailPreferences_SetMyClubEmailConsent($consent: Boolean!) {\n        setMyClubEmailConsent(consent: $consent) {\n            ...EditAccountEmailPreferences_UserFragment\n        }\n    }\n"): (typeof documents)["\n    mutation EditAccountEmailPreferences_SetMyClubEmailConsent($consent: Boolean!) {\n        setMyClubEmailConsent(consent: $consent) {\n            ...EditAccountEmailPreferences_UserFragment\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation EditAccountEmailPreferences_SetMyBusinessEmailConsent($consent: Boolean!) {\n        setMyBusinessEmailConsent(consent: $consent) {\n            ...EditAccountEmailPreferences_UserFragment\n        }\n    }\n"): (typeof documents)["\n    mutation EditAccountEmailPreferences_SetMyBusinessEmailConsent($consent: Boolean!) {\n        setMyBusinessEmailConsent(consent: $consent) {\n            ...EditAccountEmailPreferences_UserFragment\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment EditAccountEmails_UserFragment on User {\n        id\n        emails {\n            email\n            confirmed\n        }\n    }\n"): (typeof documents)["\n    fragment EditAccountEmails_UserFragment on User {\n        id\n        emails {\n            email\n            confirmed\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation EditAccountProfile_AddMyEmail($newEmail: String!) {\n        addMyEmail(newEmail: $newEmail) {\n            id\n            emails {\n                email\n                confirmed\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation EditAccountProfile_AddMyEmail($newEmail: String!) {\n        addMyEmail(newEmail: $newEmail) {\n            id\n            emails {\n                email\n                confirmed\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation EditAccountProfile_RemoveMyEmail($email: String!) {\n        removeMyEmail(email: $email) {\n            id\n            emails {\n                email\n                confirmed\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation EditAccountProfile_RemoveMyEmail($email: String!) {\n        removeMyEmail(email: $email) {\n            id\n            emails {\n                email\n                confirmed\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment EditAccountProfile_UserFragment on User {\n        id\n        givenName\n        fullName\n        profilePicture\n    }\n"): (typeof documents)["\n    fragment EditAccountProfile_UserFragment on User {\n        id\n        givenName\n        fullName\n        profilePicture\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation EditAccountProfile_SetMyGivenName($newGivenName: String!) {\n        setMyGivenName(newGivenName: $newGivenName) {\n            ...EditAccountProfile_UserFragment\n        }\n    }\n"): (typeof documents)["\n    mutation EditAccountProfile_SetMyGivenName($newGivenName: String!) {\n        setMyGivenName(newGivenName: $newGivenName) {\n            ...EditAccountProfile_UserFragment\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query ForceOnboarding_GetMe {\n        me {\n            preferences {\n                onboarded\n            }\n        }\n    }\n"): (typeof documents)["\n    query ForceOnboarding_GetMe {\n        me {\n            preferences {\n                onboarded\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment LinkLegacyMembership_UserFragment on User {\n        ...MembershipCard_UserFragment\n    }\n"): (typeof documents)["\n    fragment LinkLegacyMembership_UserFragment on User {\n        ...MembershipCard_UserFragment\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation LinkLegacyMembership_ValidateLegacyMembership($validationInfo: LegacyMembershipValidationInfo!) {\n        validateLegacyMembership(validationInfo: $validationInfo) {\n            ... on LegacyMembershipValidationResultFail {\n                reason\n            }\n            ... on LegacyMembershipValidationResultMoreInfo {\n                supplyOrderNum\n                supplyEmail\n                supplyBillingName\n                supplyPhone\n                minRequiredValidators\n            }\n            ... on LegacyMembershipValidationResultSuccess {\n                newMembership {\n                    ...MembershipCard_MembershipFragment\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation LinkLegacyMembership_ValidateLegacyMembership($validationInfo: LegacyMembershipValidationInfo!) {\n        validateLegacyMembership(validationInfo: $validationInfo) {\n            ... on LegacyMembershipValidationResultFail {\n                reason\n            }\n            ... on LegacyMembershipValidationResultMoreInfo {\n                supplyOrderNum\n                supplyEmail\n                supplyBillingName\n                supplyPhone\n                minRequiredValidators\n            }\n            ... on LegacyMembershipValidationResultSuccess {\n                newMembership {\n                    ...MembershipCard_MembershipFragment\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query LoggedInConditional_GetMe {\n        me {\n            ...LoggedInConditional_UserFragment\n        }\n    }\n"): (typeof documents)["\n    query LoggedInConditional_GetMe {\n        me {\n            ...LoggedInConditional_UserFragment\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment LoggedInConditional_UserFragment on User {\n        id\n        givenName\n        fullName\n        role {\n            id\n            scopes\n        }\n    }\n"): (typeof documents)["\n    fragment LoggedInConditional_UserFragment on User {\n        id\n        givenName\n        fullName\n        role {\n            id\n            scopes\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment ManageUser_UserFragment on User {\n        id\n        fullName\n        givenName\n        ucfEmail\n        earliestMembershipTimestamp\n        hasDemographics\n        membership {\n            ...MembershipCard_MembershipFragment\n        }\n        preferences {\n            onboarded\n        }\n\n        ...ProfilePicture_UserFragment\n        ...MembershipCard_UserFragment\n    }\n"): (typeof documents)["\n    fragment ManageUser_UserFragment on User {\n        id\n        fullName\n        givenName\n        ucfEmail\n        earliestMembershipTimestamp\n        hasDemographics\n        membership {\n            ...MembershipCard_MembershipFragment\n        }\n        preferences {\n            onboarded\n        }\n\n        ...ProfilePicture_UserFragment\n        ...MembershipCard_UserFragment\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation ManageUser_SetNames($userId: ID!, $newGivenName: String!, $newFullName: String!) {\n        setGivenName(userId: $userId, newGivenName: $newGivenName) {\n            id\n        }\n        setFullName(userId: $userId, newFullName: $newFullName) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation ManageUser_SetNames($userId: ID!, $newGivenName: String!, $newFullName: String!) {\n        setGivenName(userId: $userId, newGivenName: $newGivenName) {\n            id\n        }\n        setFullName(userId: $userId, newFullName: $newFullName) {\n            id\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation ManageUser_ClearProfilePicture($userId: ID!) {\n        clearProfilePicture(userId: $userId) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation ManageUser_ClearProfilePicture($userId: ID!) {\n        clearProfilePicture(userId: $userId) {\n            id\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation ManageUser_ForceReOnboard($userId: ID!) {\n        forceReOnboard(userId: $userId) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation ManageUser_ForceReOnboard($userId: ID!) {\n        forceReOnboard(userId: $userId) {\n            id\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation ManageUser_GrantMembership($userId: ID!, $timestamp: Int!) {\n        grantMembership(userId: $userId, timestamp: $timestamp) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation ManageUser_GrantMembership($userId: ID!, $timestamp: Int!) {\n        grantMembership(userId: $userId, timestamp: $timestamp) {\n            id\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation ManageUser_RevokeMembership($userId: ID!) {\n        revokeMembership(userId: $userId) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation ManageUser_RevokeMembership($userId: ID!) {\n        revokeMembership(userId: $userId) {\n            id\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment ManageUserCard_UserFragment on User {\n        id\n        fullName\n        membership {\n            __typename\n        }\n        hasDemographics\n\n        ...ProfilePicture_UserFragment\n    }\n"): (typeof documents)["\n    fragment ManageUserCard_UserFragment on User {\n        id\n        fullName\n        membership {\n            __typename\n        }\n        hasDemographics\n\n        ...ProfilePicture_UserFragment\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment MembershipCard_UserFragment on User {\n        id\n        fullName\n    }\n"): (typeof documents)["\n    fragment MembershipCard_UserFragment on User {\n        id\n        fullName\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment MembershipCard_MembershipFragment on Membership {\n        purchaseTimestamp\n        expiresAtTimestamp\n        renewableAtTimestamp\n        flags {\n            name\n            color\n            visible\n        }\n    }\n"): (typeof documents)["\n    fragment MembershipCard_MembershipFragment on Membership {\n        purchaseTimestamp\n        expiresAtTimestamp\n        renewableAtTimestamp\n        flags {\n            name\n            color\n            visible\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment ProfilePicture_UserFragment on User {\n        id\n        profilePicture\n    }\n"): (typeof documents)["\n    fragment ProfilePicture_UserFragment on User {\n        id\n        profilePicture\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment ProjectCard_ProjectFragment on Project {\n        id\n        name\n        accessibleRoles {\n            id\n        }\n        sessions {\n            id\n            name\n        }\n    }\n"): (typeof documents)["\n    fragment ProjectCard_ProjectFragment on Project {\n        id\n        name\n        accessibleRoles {\n            id\n        }\n        sessions {\n            id\n            name\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query SearchAllUsers($search: String!) {\n        searchAllUsers(search: $search) {\n            ...ManageUserCard_UserFragment\n        }\n    }\n"): (typeof documents)["\n    query SearchAllUsers($search: String!) {\n        searchAllUsers(search: $search) {\n            ...ManageUserCard_UserFragment\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation UploadProfilePicture_SetMyProfilePictureMutation($profilePicture: Upload!) {\n  setMyProfilePicture(profilePicture: $profilePicture) {\n    id\n    profilePicture\n  }\n}"): (typeof documents)["\nmutation UploadProfilePicture_SetMyProfilePictureMutation($profilePicture: Upload!) {\n  setMyProfilePicture(profilePicture: $profilePicture) {\n    id\n    profilePicture\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment OnboardingEmails_UserFragment on User {\n        id\n        ...EditAccountEmails_UserFragment\n        ...EditAccountEmailPreferences_UserFragment\n    }\n"): (typeof documents)["\n    fragment OnboardingEmails_UserFragment on User {\n        id\n        ...EditAccountEmails_UserFragment\n        ...EditAccountEmailPreferences_UserFragment\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation OnboardingFinish_CreateMembershipPurchaseSession {\n        finishMyOnboarding {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation OnboardingFinish_CreateMembershipPurchaseSession {\n        finishMyOnboarding {\n            id\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment OnboardingMembership_UserFragment on User {\n        id\n        ...MembershipCard_UserFragment\n        ...LinkLegacyMembership_UserFragment\n        membership {\n            ...MembershipCard_MembershipFragment\n        }\n    }\n"): (typeof documents)["\n    fragment OnboardingMembership_UserFragment on User {\n        id\n        ...MembershipCard_UserFragment\n        ...LinkLegacyMembership_UserFragment\n        membership {\n            ...MembershipCard_MembershipFragment\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation OnboardingMembership_CreateMembershipPurchaseSession {\n        createMembershipPurchaseSession {\n            id\n            paymentLink\n        }\n    }\n"): (typeof documents)["\n    mutation OnboardingMembership_CreateMembershipPurchaseSession {\n        createMembershipPurchaseSession {\n            id\n            paymentLink\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment OnboardingProfile_UserFragment on User {\n        id\n        ...EditAccountProfile_UserFragment\n    }\n"): (typeof documents)["\n    fragment OnboardingProfile_UserFragment on User {\n        id\n        ...EditAccountProfile_UserFragment\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation SubmitUserJwtForAttendance($subjectType: String!, $subjectId: ID!, $jwtCode: String!) {\n        submitUserJwtForAttendance(subjectType: $subjectType, subjectId: $subjectId, jwtCode: $jwtCode) {\n            ...AdminProjectAttendanceAdminscannerPage_AdminScanAttendanceResultFragment\n        }\n    }\n"): (typeof documents)["\n    mutation SubmitUserJwtForAttendance($subjectType: String!, $subjectId: ID!, $jwtCode: String!) {\n        submitUserJwtForAttendance(subjectType: $subjectType, subjectId: $subjectId, jwtCode: $jwtCode) {\n            ...AdminProjectAttendanceAdminscannerPage_AdminScanAttendanceResultFragment\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment AdminProjectAttendanceAdminscannerPage_AdminScanAttendanceResultFragment on AdminScanAttendanceResult {\n        __typename\n        ... on AdminScanAttendanceResultSuccess {\n            timestamp\n            type\n            subject {\n                id\n                name\n            }\n            user {\n                id\n                fullName\n                membership {\n                    __typename\n                }\n                hasDemographics\n\n                ...ProfilePicture_UserFragment\n            }\n        }\n        ... on AdminScanAttendanceResultFail {\n            reason\n        }\n    }\n"): (typeof documents)["\n    fragment AdminProjectAttendanceAdminscannerPage_AdminScanAttendanceResultFragment on AdminScanAttendanceResult {\n        __typename\n        ... on AdminScanAttendanceResultSuccess {\n            timestamp\n            type\n            subject {\n                id\n                name\n            }\n            user {\n                id\n                fullName\n                membership {\n                    __typename\n                }\n                hasDemographics\n\n                ...ProfilePicture_UserFragment\n            }\n        }\n        ... on AdminScanAttendanceResultFail {\n            reason\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GenerateLiveJwtQr($projectSessionId: ID!) {\n        generateLiveJwtQr(subjectType: \"ProjectSession\", subjectId: $projectSessionId)\n    }\n"): (typeof documents)["\n    query GenerateLiveJwtQr($projectSessionId: ID!) {\n        generateLiveJwtQr(subjectType: \"ProjectSession\", subjectId: $projectSessionId)\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation ConfirmEmailHandler_ConfirmEmail($code: String!) {\n        confirmEmail(code: $code)\n    }\n"): (typeof documents)["\n    mutation ConfirmEmailHandler_ConfirmEmail($code: String!) {\n        confirmEmail(code: $code)\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query ConfirmEmailHandler_GetMe {\n        me {\n            preferences {\n                onboarded\n            }\n        }\n    }\n"): (typeof documents)["\n    query ConfirmEmailHandler_GetMe {\n        me {\n            preferences {\n                onboarded\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query EcwidSsoHandler_GetMe {\n        me {\n            id\n            fullName\n            membership {\n                purchaseTimestamp\n            }\n        }\n    }\n"): (typeof documents)["\n    query EcwidSsoHandler_GetMe {\n        me {\n            id\n            fullName\n            membership {\n                purchaseTimestamp\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation PurchaseMembershipHandler_CreateMembershipPurchaseSession {\n        createMembershipPurchaseSession {\n            id\n            paymentLink\n        }\n    }\n"): (typeof documents)["\n    mutation PurchaseMembershipHandler_CreateMembershipPurchaseSession {\n        createMembershipPurchaseSession {\n            id\n            paymentLink\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query RDContractHandler_GetMe {\n        me {\n            id\n        }\n    }\n"): (typeof documents)["\n    query RDContractHandler_GetMe {\n        me {\n            id\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation SubmitUserJwtForEventDocument($jwtCode: String!, $override: Boolean!) {\n        submitUserJwtForTemp_GBM_3_27_2025(jwtCode: $jwtCode, override: $override) {\n            ...AdminScannerPage_ACPScanResultFragment\n        }\n    }\n"): (typeof documents)["\n    mutation SubmitUserJwtForEventDocument($jwtCode: String!, $override: Boolean!) {\n        submitUserJwtForTemp_GBM_3_27_2025(jwtCode: $jwtCode, override: $override) {\n            ...AdminScannerPage_ACPScanResultFragment\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment AdminScannerPage_ACPScanResultFragment on ACPScanResult {\n        __typename\n        ... on ACPScanResultSuccess {\n            timestamp\n            user {\n                id\n                fullName\n                membership {\n                    __typename\n                }\n                hasDemographics\n\n                ...ProfilePicture_UserFragment\n            }\n        }\n        ... on ACPScanResultFail {\n            reason\n            canOverride\n        }\n    }\n"): (typeof documents)["\n    fragment AdminScannerPage_ACPScanResultFragment on ACPScanResult {\n        __typename\n        ... on ACPScanResultSuccess {\n            timestamp\n            user {\n                id\n                fullName\n                membership {\n                    __typename\n                }\n                hasDemographics\n\n                ...ProfilePicture_UserFragment\n            }\n        }\n        ... on ACPScanResultFail {\n            reason\n            canOverride\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query AccountDemographicsPage_GetMe {\n        me {\n            hasDemographics\n        }\n    }\n"): (typeof documents)["\n    query AccountDemographicsPage_GetMe {\n        me {\n            hasDemographics\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query AccountDemographicsPage_GetMyDemographicsSurveyUrl {\n        myDemographicsSurveyUrl\n    }\n"): (typeof documents)["\n    query AccountDemographicsPage_GetMyDemographicsSurveyUrl {\n        myDemographicsSurveyUrl\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query AccountEmailsPage_GetMe {\n        me {\n            ...EditAccountEmails_UserFragment\n            ...EditAccountEmailPreferences_UserFragment\n        }\n    }\n"): (typeof documents)["\n    query AccountEmailsPage_GetMe {\n        me {\n            ...EditAccountEmails_UserFragment\n            ...EditAccountEmailPreferences_UserFragment\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query AccountLayout_GetMe {\n        me {\n            ...AccountNavigationSidebar_UserFragment\n        }\n    }\n"): (typeof documents)["\n    query AccountLayout_GetMe {\n        me {\n            ...AccountNavigationSidebar_UserFragment\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query AccountLegacyMembershipPageBody_GetMe {\n        me {\n            ...LinkLegacyMembership_UserFragment\n        }\n    }\n"): (typeof documents)["\n    query AccountLegacyMembershipPageBody_GetMe {\n        me {\n            ...LinkLegacyMembership_UserFragment\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query AccountMembershipPage_GetMe {\n        me {\n            ...MembershipCard_UserFragment\n            membership {\n                ...MembershipCard_MembershipFragment\n            }\n        }\n    }\n"): (typeof documents)["\n    query AccountMembershipPage_GetMe {\n        me {\n            ...MembershipCard_UserFragment\n            membership {\n                ...MembershipCard_MembershipFragment\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation AccountMembershipPageBody_CreateMembershipPurchaseSession {\n        createMembershipPurchaseSession {\n            id\n            paymentLink\n        }\n    }\n"): (typeof documents)["\n    mutation AccountMembershipPageBody_CreateMembershipPurchaseSession {\n        createMembershipPurchaseSession {\n            id\n            paymentLink\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query AccountProfilePage_GetMe {\n        me {\n            ...EditAccountProfile_UserFragment\n            ...ClickableProfilePicture_UserFragment\n        }\n    }\n"): (typeof documents)["\n    query AccountProfilePage_GetMe {\n        me {\n            ...EditAccountProfile_UserFragment\n            ...ClickableProfilePicture_UserFragment\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query AdminLayout_GetMe {\n        me {\n            ...AdminNavigationSidebar_UserFragment\n        }\n    }\n"): (typeof documents)["\n    query AdminLayout_GetMe {\n        me {\n            ...AdminNavigationSidebar_UserFragment\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query AdminProjectsPage_GetMeAndProjects {\n        me {\n            role {\n                id\n                scopes\n            }\n        }\n        projects {\n            ...AllProjectsList_ProjectFragment\n        }\n    }\n"): (typeof documents)["\n    query AdminProjectsPage_GetMeAndProjects {\n        me {\n            role {\n                id\n                scopes\n            }\n        }\n        projects {\n            ...AllProjectsList_ProjectFragment\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query AdminUserPage_GetMe {\n        me {\n            role {\n                id\n                scopes\n            }\n        }\n    }\n"): (typeof documents)["\n    query AdminUserPage_GetMe {\n        me {\n            role {\n                id\n                scopes\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query AdminUsersPage_GetUser($userId: ID!) {\n        user(id: $userId) {\n            ...ManageUser_UserFragment\n        }\n    }\n"): (typeof documents)["\n    query AdminUsersPage_GetUser($userId: ID!) {\n        user(id: $userId) {\n            ...ManageUser_UserFragment\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query AdminUsersPage_GetMe {\n        me {\n            role {\n                id\n                scopes\n            }\n        }\n    }\n"): (typeof documents)["\n    query AdminUsersPage_GetMe {\n        me {\n            role {\n                id\n                scopes\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetProjectAndSession($projectId: ID!, $projectSessionId: ID!) {\n        project(id: $projectId) {\n            id\n            name\n            session(id: $projectSessionId) {\n                id\n                name\n            }\n        }\n    }\n"): (typeof documents)["\n    query GetProjectAndSession($projectId: ID!, $projectSessionId: ID!) {\n        project(id: $projectId) {\n            id\n            name\n            session(id: $projectSessionId) {\n                id\n                name\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetProjectSession($projectId: ID!, $projectSessionId: ID!) {\n        project(id: $projectId) {\n            id\n            name\n            session(id: $projectSessionId) {\n                id\n                name\n                weeks {\n                    title\n                    startTimestamp\n                    endTimestamp\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    query GetProjectSession($projectId: ID!, $projectSessionId: ID!) {\n        project(id: $projectId) {\n            id\n            name\n            session(id: $projectSessionId) {\n                id\n                name\n                weeks {\n                    title\n                    startTimestamp\n                    endTimestamp\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetProjectSessionAttendanceRecords($projectId: ID!, $projectSessionId: ID!, $filter: AttendanceRecordsFilterInput) {\n        project(id: $projectId) {\n            session(id: $projectSessionId) {\n                attendanceRecords(filter: $filter) {\n                    id\n                    timestamp\n                    type\n                    user {\n                        ...ManageUserCard_UserFragment\n                        id\n                        fullName\n                        givenName\n                        membership {\n                            __typename\n                        }\n                        hasDemographics\n                    }\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    query GetProjectSessionAttendanceRecords($projectId: ID!, $projectSessionId: ID!, $filter: AttendanceRecordsFilterInput) {\n        project(id: $projectId) {\n            session(id: $projectSessionId) {\n                attendanceRecords(filter: $filter) {\n                    id\n                    timestamp\n                    type\n                    user {\n                        ...ManageUserCard_UserFragment\n                        id\n                        fullName\n                        givenName\n                        membership {\n                            __typename\n                        }\n                        hasDemographics\n                    }\n                }\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GenerateStaticJwtQrDocument($projectSessionId: ID!) {\n        generateStaticJwtQr(subjectType: \"ProjectSession\", subjectId: $projectSessionId)\n    }\n"): (typeof documents)["\n    query GenerateStaticJwtQrDocument($projectSessionId: ID!) {\n        generateStaticJwtQr(subjectType: \"ProjectSession\", subjectId: $projectSessionId)\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation SubmitAttendanceJwt($jwtCode: String!) {\n        submitAttendanceJwt(jwtCode: $jwtCode) {\n            __typename\n            ... on SelfServiceAttendanceResultSuccess {\n                timestamp\n                type\n                subject {\n                    __typename\n                    id\n                    name\n                    ... on ProjectSession {\n                        project {\n                            id\n                            name\n                        }\n                    }\n                }\n            }\n            ... on SelfServiceAttendanceResultFail {\n                reason\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation SubmitAttendanceJwt($jwtCode: String!) {\n        submitAttendanceJwt(jwtCode: $jwtCode) {\n            __typename\n            ... on SelfServiceAttendanceResultSuccess {\n                timestamp\n                type\n                subject {\n                    __typename\n                    id\n                    name\n                    ... on ProjectSession {\n                        project {\n                            id\n                            name\n                        }\n                    }\n                }\n            }\n            ... on SelfServiceAttendanceResultFail {\n                reason\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query MyQrPageBody_GetMyJwtQr {\n        myJwtQr\n        me {\n            ...ProfilePicture_UserFragment\n            fullName\n        }\n    }\n"): (typeof documents)["\n    query MyQrPageBody_GetMyJwtQr {\n        myJwtQr\n        me {\n            ...ProfilePicture_UserFragment\n            fullName\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query OnboardingPageBody_GetMe {\n        me {\n            id\n            preferences {\n                onboarded\n            }\n            ...OnboardingProfile_UserFragment\n            ...OnboardingEmails_UserFragment\n            ...OnboardingMembership_UserFragment\n        }\n    }\n"): (typeof documents)["\n    query OnboardingPageBody_GetMe {\n        me {\n            id\n            preferences {\n                onboarded\n            }\n            ...OnboardingProfile_UserFragment\n            ...OnboardingEmails_UserFragment\n            ...OnboardingMembership_UserFragment\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query PaymentCallbackPage_GetMe {\n        me {\n            preferences {\n                onboarded\n            }\n        }\n    }\n"): (typeof documents)["\n    query PaymentCallbackPage_GetMe {\n        me {\n            preferences {\n                onboarded\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nquery GetIsMembershipPurchaseSessionCompleted_PaymentCallbackPage($id: ID!) {\n  isMembershipPurchaseSessionCompleted(id: $id)\n}\n"): (typeof documents)["\nquery GetIsMembershipPurchaseSessionCompleted_PaymentCallbackPage($id: ID!) {\n  isMembershipPurchaseSessionCompleted(id: $id)\n}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query Temp_bowlingStatus {\n        temp_bowlingStatus {\n            totalAdmitted\n            reservationsUsed\n        }\n    }\n"): (typeof documents)["\n    query Temp_bowlingStatus {\n        temp_bowlingStatus {\n            totalAdmitted\n            reservationsUsed\n        }\n    }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;